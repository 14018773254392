import cx from 'classnames'

interface ITypografix {
  children: React.ReactNode
  className?: string
  id?: string
}

export const H1: React.FC<ITypografix> = ({ children, className, id }) => {
  return (
    <h1
      className={cx('text-3xl font-bold mt-4 mb-6', className)}
      id={id}
    >
      {children}
    </h1>
  );
}

export const H2: React.FC<ITypografix> = ({ children, className, id }) => {
  return (
    <h2
      className={cx('text-2xl font-bold mt-4 mb-6', className)}
      id={id}
    >
      {children}
    </h2>
  );
}

export const H3: React.FC<ITypografix> = ({ children, className, id }) => {
  return (
    <h3
      className={cx('text-xl font-bold mt-4 mb-6', className)}
      id={id}
    >
      {children}
    </h3>
  );
}

export const P: React.FC<ITypografix> = ({ children, className }) => {
  return (
    <p className={cx('mb-4', className)}>
      {children}
    </p>
  );
}

export const List: React.FC<ITypografix> = ({ children, className }) => {
  return (
    <ul className={cx('mb-4', className)}>
      {children}
    </ul>
  );
}

export const Li: React.FC<ITypografix> = ({ children, className }) => {
  return (
    <li className={cx('mb-4', className)}>
      {children}
    </li>
  );
}

interface ILink {
  children: React.ReactNode
  className?: string
  href: string
}

export const A: React.FC<ILink> = ({ children, className, href }) => {
  return (
    <a href={href} className={cx('text-primary-500 hover:text-primary-700 active:text-primary-800', className)}>
      {children}
    </a>
  );
}

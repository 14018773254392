import React from 'react';
import { Container, H1 } from 'shared/ui'

interface IHasChildren {
  children: React.ReactNode
}

export const Layout: React.FC<IHasChildren> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen max-w-xl m-auto">
      <Header />

      <main className="flex-grow">{children}</main>

      <Footer />
    </div>
  )
}

const Header: React.FC = () => {
  return (
    <Container as="header" className="text-center border-b-2">
      <div className="text-2xl font-bold mt-2 mb-2">Важен каждый отзыв</div>
    </Container>
  )
}

const Footer: React.FC = () => {
  return (
    <Container as="footer" className="text-sm border-t-2">
      &copy; Важен каждый отзыв - {(new Date().getFullYear())}
    </Container>
  )
}

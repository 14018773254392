import React from 'react';
import { withProviders } from "./providers";
import { Routing } from "pages";

import './styles.css'

const _App = () => {
  return (
    <Routing />
  );
}

export const App = withProviders(_App) 

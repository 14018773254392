import { lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';

import { Spinner } from 'shared/ui';

const Home = lazy(() => import("./home"));
const Point = lazy(() => import("./point"));
const Stat = lazy(() => import("./stat"));
const Policy = lazy(() => import("./policy"));
const Offer = lazy(() => import("./offerta"));

export const Routing = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/p/:uuid" element={<Point />} />
        <Route path="/p/:uuid/stat" element={<Stat />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/offer" element={<Offer />} />
      </Routes>
    </Suspense>
  );
};

import React from 'react';
import cx from 'classnames';

interface IContainer {
  as?: React.ElementType
  children: React.ReactNode
  className?: string
}

export const Container: React.FC<IContainer> = ({ children, className, as = 'div' }) => {
  const ElementType = as;

  return <ElementType className={cx('p-4', className)}>{children}</ElementType>;
};
